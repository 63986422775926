import React from 'react'


function Redirection() {
    const monUrl = 'https://app.cyberimpact.com/clients/40257/subscribe-forms/82E0D91B-3A54-4109-966F-1A1625EEC84E';
    //window.open(url, "_blanck");
    window.open(monUrl, "_self");
  }

const Download = () => {
    return (
        <div>
             <i onClick={Redirection}
          className=" hover:text-red-600 transform hover:scale-150 transition ease-in duration-300 p-2 pointer h-8 w-12  ml-8 mr-4  fas fa-download"></i>
        
        </div>
    )
}

export default Download
