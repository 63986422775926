import React from "react";
import About from "../img/about.png";
import Footer from "../components/Footer";
import { Container } from "../components/styles/Container.styled";

const QuiSommesNous = () => {
  return (
    <div className=" h-screen p-2 text-yellow-800  bg-gray-100  rounded ">
      <header>Page démo de présentation d'un groupe'</header>
      <Container>
        <div className="p-4 text-green-600 bg-yellow-100  ">
          {" "}
          Voici un groupe...qui n'est pas Groupe Mulino
          <img
            src={About}
            className="h-1/3 w-full md:w-1/2 ml-auto mr-auto rounded shadow mt-4"
            alt="about"
          />
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default QuiSommesNous;
