import React from "react";
import logo from "../img/logo.png";
import Footer from "../components/Footer";
import ToastBoostrap from "../components/ToastBoostrap";
import OverToast from "../components/OverToast";
import { Container } from "../components/styles/Container.styled";
import { Link } from "react-router-dom";
// GlobalStyles Ne marche pas

const Home = () => {
  return (
    <Container>
      <div className="h-screen ">
        <OverToast />
      

        <header className="App-header h-4/6">
          <img src={logo} className="App-logo rounded shadow mt-4" alt="logo" />

          <p>
            <code className="montserrat">Le Groupe Mulino</code>
          </p>

          <a
            className="App-link"
            href="/public/contact.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className=" transform  hover:scale-110  hover:text-yellow-400">
              <Link className="p-8" to="/contact">
                Nous joindre
              </Link>
            </div>
          </a>
        </header>

        <Footer />
      </div>
    </Container>
  );
};

export default Home;
