import styled from "styled-components";

export const ButtonCSS = styled.div`
background-color: white;
&:hover {
  background-color: green;
}
color:white;
padding:2px 2px;
border: 2px solid black;
border-radius: 5px;
font-weight:700;
cursor:pointer;

`
