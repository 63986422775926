import "./App.css";
import './styles.css'

import Home from "./pages/Home";
import NosProjets from "./pages/NosProjets";
import QuiSommesNous from "./pages/QuiSommesNous";
import Errors from "./components/Errors";
import Nav from "./components/Nav";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Maison1 from "./components/Maison1";
import { ContactForm } from "./pages/ContactForm";
import MapFiles from "./components/MapFiles";

const App = () => {
  return (
    <Router className="h-screen">
      <Nav />

      <Routes>
      <Route path="/nosprojets/maison1" element={<Maison1/>}></Route>
        <Route path="/nosprojets" element={<NosProjets />}></Route>
        <Route path="/nosprojets:idMaison" element={<NosProjets />}></Route>
        <Route path="/qui" element={<QuiSommesNous />}></Route>
        <Route path="/contact" element={< ContactForm/>}></Route>
        <Route path="/mapFiles" element={< MapFiles/>}></Route>
        <Route path="*" element={<Errors />}></Route>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
