import React from "react";

const Xhidden = () => {
  function RedirectionMaison() {
    document.getElementById("myAlert").style.display = "none";
    document.getElementById('AlertTop').style.display = "none";
    
  }
  return (
    <div>
      <i
        onClick={RedirectionMaison}
        className=" hover:text-red-600 transform hover:scale-150 transition ease-in duration-300 p-2 pointer h-8 w-12  ml-8 mr-4  fas fa-times-circle"
      ></i>
    </div>
  );
};

export default Xhidden;
