import React from "react";
import FootersansWebmail from './FooterSansWebmail'
const Maison1 = () => {
 
  return (
    <div className="flex justify-center items-center text-2xl w-full h-24 mt-4 bg-gray-100">
      Détail de la Maison 1
<FootersansWebmail/>
    </div>
  );
};

export default Maison1;
