import React from "react";
import { Link } from "react-router-dom";
import FooterSansWebmail from "../components/FooterSansWebmail";

import appartement from "../img/appartement.jpg";

const NosProjets = () => {
  function Redirection() {
    const monUrl = "/modaltest.html";
    window.open(monUrl, "_self");
    //window.open(monUrl, "_blanck");
  }
  return (
    <div className=" p-2 text-gray-100 flex justify-center items-center h-screen bg-gray-800 rounded ">
      <main>
        <div className="container w-full md:px-40 mx-auto py-20">
          <div className="w-full md:grid  md:grid-cols-2 lg:grid-cols-3 md:gap-10 space-y-6 md:space-y-0 px-10 md:px-0 mx-auto">
            <div className="mt-4 md:mt-0 bg-white p-6 shadow-lg rounded-md text-black md:w-60 lg:w-72">
              <div className="grid grid-cols-2 gap-2">
                <div>
                  {" "}
                  <h3 className="text-xl text-gray-800 font-semibold mb-3">
                    Maison 1
                  </h3>
                </div>
                <div>
                  <img src={appartement} alt="apartement" />
                </div>
              </div>
              <p className="mb-2">1960</p>
              <p className="my-4">11 ave...</p>

              <button
                onClick={Redirection}
                className="mb-2 text-lg font-semibold text-gray-700 bg-indigo-100 px-4 py-1 block mx-auto rounded-md"
              >
                Informations
              </button>
              <div className="bg-yellow-400 text-gray-600 text-lg md:text-xl font-bold transform hover:scale-110 rounded shadow-xl">
                <Link className="  p-4 flex justify-center " to="/nosprojets/maison1">
                  <p className="">voir maison 1</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>

      <FooterSansWebmail />
    </div>
  );
};

export default NosProjets;
