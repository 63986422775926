import React from "react";
import Alert from "./Alert";
import Download from "./Download";
import Xhidden from "./Xhidden";



const ToastBoostrap = () => {
  function Redirection() {
    const monUrl =
      "https://app.cyberimpact.com/clients/40257/subscribe-forms/82E0D91B-3A54-4109-966F-1A1625EEC84E";
    //window.open(url, "_blanck");
    window.open(monUrl, "_self");
  }
  return (
    <div id="idBlocAlert" className="block absolute top-2">
  
      <div
        id="AlertTop"
        className="relative h-24 top-24 "
        aria-live="polite"
        aria-atomic="true"
      >
        <div  className="toast-body ">
          <div
            id="myAlert"
            className="alert-banner  bg-yellow-100 rounded-lg py-4 px-6 mb-0 mt-4 text-base text-yellow-700 inline-flex items-center w-full "
            role="alert"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="exclamation-triangle"
              className="w-4 h-4 mr-2 fill-current"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
              ></path>
            </svg>
            <p
              onClick={Redirection}
              className="ml-4 transform scale-110 hover:text-blue-600 hover:font-bold focus:text-blue-600"
            >
              Abonnez-vous pour rester en contact avec nos publications
            </p>
            <Download />
            <Xhidden />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToastBoostrap;
