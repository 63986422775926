import React from 'react'
import ToastBoostrap from './ToastBoostrap'
const OverToast = () => {
    return (
        <div id="idBlocOverAlert" className='h-0 relative ' >
            <ToastBoostrap/>
        </div>
    )
}
export default OverToast
