import React from 'react'
import Accueil from './Accueil';
import UseEffectTest from './UseEffectTest';


const ProjectsList = ({blogs,title,handleDelete}) => {
   

    // on peut aussi utiliser la destructuration du props en n'initialisant plus const blogs =props.blogs;
    // const title =props.title;  en remplaçant props par {blogs,title}
    return (
        <div>
             <h1 className='"w-full flex justify-center text-2xl font-extrabold'>
                  {title}
              </h1>
            {blogs.map(blog => (
          <div className="blog-preview border-2 border-black mb-2 grid grid-cols-2 gap-2" key={blog.id} >
             
            <h2 className='bg-red-200'>{ blog.title }</h2>
            <br />

            <p className='bg-green-100'>Construction par { blog.author }</p>
            <button onClick={()=>handleDelete(blog.id)}>Supprimer ce projet</button>
          </div>
        ))}
        <UseEffectTest/>
        <Accueil/>
        </div>
    )
}

export default ProjectsList
