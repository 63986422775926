import React from 'react'

const FooterSansWebmail = () => {
    return (
        <div className="bg-gray-200 text-gray-800 p-2 w-full h-24 md:h-12 flex justify-center items-center  py-12 w-screen fixed bottom-0">
        Copyright <i className=" ml-4 mr-2 fa fa-copyright" aria-hidden="true"></i>Groupe Mulino 2022
        </div>
    )
}

export default FooterSansWebmail
