import React from "react";
import { Link } from "react-router-dom";
import { StyledHeader } from "./styles/Header.styled";

const Nav = () => {
  return (
    <div className="bg-gray-200 sticky top-2 mb-4 ">
      <StyledHeader bg='#a2b2b2'>
      <nav className="h-1/4 md:h-1/2 grid grid-cols-2 md:grid-cols-4 gap-2 justify-between">
        <div className="  h-12 bg-blue-200 text-lg md:text-xl font-bold  transform scale-90 hover:scale-100 rounded shadow-xl">
          <Link className=" pt-2 flex justify-center items-center " to="/">Accueil</Link>
        </div>
        <div className=" h-12 bg-yellow-400 text-gray-600 text-lg md:text-xl font-bold transform scale-90 hover:scale-100 rounded shadow-xl">
          <Link className=" pt-2  flex justify-center items-center " to="/nosprojets">Nos projets</Link>
        </div>
        <div className="h-12 bg-gray-500 text-white text-lg md:text-xl font-bold transform scale-90 hover:scale-100 rounded shadow-xl">
          <Link  className="pt-2 flex justify-center items-center  " to="/qui">Qui sommes-nous?</Link>
        </div>
        <div className=" h-12 bg-red-500 text-white text-lg md:text-xl font-bold transform scale-90 hover:scale-100 rounded shadow-xl">
          <Link  className="pt-2 flex justify-center items-center " to="contact">Contact</Link>
        </div>
      </nav>
      </StyledHeader>
    </div>
  );
};

export default Nav;
