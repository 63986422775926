import React from 'react'
import { useState,useEffect } from 'react';
import ProjectsList from './ProjectsList';


const MapFiles = () => {
    const [blogs, setBlogs] = useState([
        { title: 'Maison 1', body: ' contenu de la maison 1', author: 'Jean 1', id: 1 },
        { title: 'Maison 2', body: ' contenu de la maison 2', author: 'Jean 2', id: 2 },
        { title: 'Maison 3', body: ' contenu de la maison 3', author: 'Jean 3', id: 3 },
        { title: 'Maison 1', body: ' contenu de la maison 1', author: 'Jean 1', id: 4 },
      ]);
      const [name,setName]=useState('Jean 1');

    const handleDelete = (id) =>{
        const newBlogs = blogs.filter(blog =>blog.id !==id)
        setBlogs(newBlogs);
        // on va garder seulement ceux qui ne sont pas = à id ( comme si on le supprimait de la liste blogs)

    }
  
    useEffect (() =>{
        fetch('https://jsonplaceholder.typicode.com/posts/1')
        .then((response) => response.json())
         .then((data) => console.log(data));

    })

      return (
        <div className="home grid grid-cols-1 md:grid-cols-2  gap-2 text-black bg-white px-4">
          <ProjectsList blogs={blogs} title='Tous les projets' handleDelete={handleDelete}/>
          <button className="bg-red-400 text-white h-1/4 w-full hover:bg-red-600" onClick={()=>setName('Jean 2')}>changement de nom pour Jean 2</button>
          <p>{name}</p>
          <ProjectsList blogs={blogs.filter((blog)=>blog.author ==='Jean 1')} title='Tous les projets de Jean 1'/>
        </div>
      );
}

export default MapFiles
