import React from "react";
import Footer from "../components/Footer";
import { Container } from "../components/styles/Container.styled";

export const ContactForm = () => (
  <Container>
    <div className="p-6 md:w-1/2 mx-auto mr-auto">
      <form
        method="POST"
        action="https://formsubmit.co/e9783760719a85f7e5e315fd133b8ab1"
      >
        <input
          type="hidden"
          name="_next"
          value="https://groupemulino.com/merci.html"
        />
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="first-name">Prénom</label>
            <input
              autofocus="autofocus"
              type="text"
              id="first-name"
              name="first-name"
              className="form-input px-3 py-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="last-name">Nom de famille</label>
            <input
              type="text"
              id="last-name"
              name="last-name"
              className="form-input px-3 py-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="email">Courriel</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-input px-3 py-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="phone">
              <div className="flex align-items">
                Téléphone
                <span className="ml-auto opacity-75">(Optionnel)</span>
              </div>
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="form-input px-3 py-2 rounded-md"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label htmlFor="subject">Sujet</label>
            <input
              type="text"
              id="subject"
              name="subject"
              className="form-input px-3 py-2 rounded-md"
              required
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label htmlFor="subject">
              <div className="flex align-items">
                Message
                <span className="ml-auto opacity-75">Max. 500 caractères</span>
              </div>
            </label>
            <textarea
              maxLength="500"
              rows="4"
              type="text"
              id="subject"
              name="subject"
              className="form-input px-3 py-2 rounded-md"
              required
            />
          </div>
        </div>
        <div className="flex justify-end py-4">
          <button
            type="submit"
            className="bg-red-500 text-white font-bold py-2 px-4 rounded focus:ring focus:ring-blue-300 hover:bg-green-500"
          >
            Envoyer
          </button>
        </div>
      </form>
      <Footer />
    </div>
  </Container>
);
