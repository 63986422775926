import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonCSS } from "./styles/ButtonCSS.styled";

const Accueil = () => {
  let navigate = useNavigate();
  return (
    <div className="pt-12 pb-8 flex justify-center transform  hover:scale-110">
      <ButtonCSS>
        <button
          onClick={() => navigate("/")}
          className="bg-yellow-600  text-gray-100 font-bold py-2 px-4 rounded-lg w-full md:w-full mb-2 "
        >
          Page d'accueil
        </button>
      </ButtonCSS>
    </div>
  );
};

export default Accueil;
