import React from 'react'
import {useState,useEffect} from 'react'

const UseEffectTest = () => {
const [windowWidth,setWindowWidth]=useState(window.innerWidth)

const handleResize = ()=>{setWindowWidth(window.innerWidth)}


useEffect(()=>{window.addEventListener('resize',handleResize)},[])
// le fait d'ajouter un parametre de [] à la fin permet de ne faire useEffect qu'au MOUNT de la page 1 fois
 
    return (
        <div>
 largeur d'écran est:{windowWidth}
        </div>
    )
}
export default UseEffectTest
