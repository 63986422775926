import React from "react";


const Footer = () => {
  function Redirection() {
    const monUrl = "https://s071.panelbox.net:2096";
    //window.open(url, "_self");
    window.open(monUrl, "_blanck");
  }

  return (

    <div className="bg-gray-200 text-gray-800 p-2  h-24 md:h-12 grid grid-cols-2  py-12 w-screen fixed bottom-0">

    <div className="text-center text-sm md:text-lg">Copyright <i className=" mr-2 fa fa-copyright " aria-hidden="true"></i>Groupe Mulino 2022
    </div>

    <div>  <button className="ml-4 md:ml-0 bg-gray-400 text-gray-800 px-2 text-sm md:text-lg" onClick={Redirection}>Webmail</button></div>

     
    </div>
  );
};
export default Footer;
