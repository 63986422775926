import styled from "styled-components";

export const Container =styled.div
`
width:90%;
max-width:100%;
padding: 0 20px;
margin:0 auto;
backgroud-color: red;

`
