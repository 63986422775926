import React from 'react'

const Errors = () => {
    return (
        <div>
            Erreurs. Il n'y a pas de page à cette adresse
        </div>
    )
}

export default Errors


